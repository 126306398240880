import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { Box, Container, Typography } from "@mui/material";
import { graphql, Link } from "gatsby";
import React from "react";
import Layout from "../components/base-structure/Layout";
import Seo from "../components/common/SEO";
import "../styles/InnerPage.scss";

export default function innerPage({ data }) {
  const record = data?.strapiBlog;
  const image = record?.Image ? record?.Image[0].url : "";

  return (
    <Layout>
      <meta name="tags" content={record?.Tags} />
      <Seo
        title={record?.metaTitle}
        description={record?.metaDescription}
        data={record}
        pageUrl="blogs"
      />
      <Container>
        <>
          <Box className="blog-head">
            <Typography variant="h1">{record.Title}</Typography>
          </Box>
          <Box className="blog-info">
            <Link to={`/author/${record?.author_profile?.Slug}/`}>
              <Box className="info-content">
                <PersonOutlineIcon className="info-icon" />
                <Typography variant="span">
                  {record?.author_profile?.Name}
                </Typography>
              </Box>
            </Link>
            <Box className="info-content">
              <CalendarMonthIcon className="info-icon" />
              <Typography variant="span">
                {record.createdAt.substring(0, 10)}
              </Typography>
            </Box>
          </Box>
          <Box className="blog-image">
            <img src={image} alt="blog" />
          </Box>
          <Box
            className="blog-content"
            dangerouslySetInnerHTML={{ __html: record?.check?.data?.check }}
          />
          <span dangerouslySetInnerHTML={{ __html: record?.Add_Schema }} />
        </>
      </Container>
    </Layout>
  );
}

export const query = graphql`
  query singleBlog($Slug: String) {
    strapiBlog(Slug: { eq: $Slug }) {
      id
      Title
      Content
      Slug
      metaKeywords
      metaDescription
      metaTitle
      Image {
        url
      }
      check {
        data {
          check
        }
      }
      Tags
      createdAt
      Add_Schema
      Add_Canonical_Link
      author_profile {
        Slug
        Name
      }
      categories {
        Name
      }
    }
  }
`;
